import React, { useState } from 'react';
import './App.css';

import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/ToolBar';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import CircularProgress from '@material-ui/core/CircularProgress';

import Web3 from 'web3';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

function App() {
  const [address, setAddress] = useState('');
  const [imageOne, setImageOne] = useState('');
  const [imageTwo, setImageTwo] = useState('');
  const [imageThree, setImageThree] = useState('');
  const [imageFour, setImageFour] = useState('');
  const [imageFive, setImageFive] = useState('');
  const [open, setOpen] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState('');

  const [preview, setViewPreview] = useState('');

  const [addressErr, setAddressErr] = useState('');

  const [bannerStyle, setBannerStyle] = useState('');

  const [galleryOpen, setGalleryOpen] = useState(false);

  const [gotPreview, setGotPreview] = useState('false');

  const openSeaApiUrl = 'https://api.opensea.io/api/v1/asset';


  const handleGalleryClickOpen = () => {
    setGalleryOpen(true);
  };

  const handleGalleryClose = () => {
    setGalleryOpen(false);
  };

  const handleClickOpen = () => {
    if (address === '') {
      setAddressErr('You must connect to your wallet.')
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setViewPreview(false);
    setOpen(false);
  };

  const connectToWallet = async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        // Request account access if needed
        await window.ethereum.enable();
        const getAddress = await web3.eth.getAccounts();
        if (getAddress.length > 0) {
          const address = getAddress[0];
          setAddress(address);
          setAddressErr('')
        }
        return web3;
      } catch (error) {
        console.error(error);
      }
    }
    // Legacy dapp browsers...
    else if (window.web3) {
      // Use Mist/MetaMask's provider.
      const web3 = window.web3;
      console.log('Injected web3 detected.');
      return web3;
    }
    // Fallback to localhost; use dev console port by default...
    else {
      const provider = new Web3.providers.HttpProvider('http://127.0.0.1:9545');
      const web3 = new Web3(provider);
      console.log('No web3 instance injected, using Local web3.');
      return web3;
    }

  }

  const canvas = document.getElementById('canvas');


  const viewPreview = async () => {
    const background = new Image();
    background.setAttribute('crossOrigin', 'anonymous');

    setGotPreview(false);

    if (bannerStyle === '5_equal_light') {
      background.src = 'images/base_img.png';
    } else if (bannerStyle === '5_diff_dark') {
      background.src = 'images/base_image_5_2.png';
    } else if (bannerStyle === '5_diff_light') {
      background.src = 'images/base_image_5_3.png';
    } else if (bannerStyle === '3_equal_light') {
      background.src = 'images/base_image_3.png';
    }

    canvas.width = background.width * window.devicePixelRatio;
    canvas.height = background.height * window.devicePixelRatio;

    canvas.style.width = '100%';
    canvas.style.height = '200px';
    canvas.style.paddingTop = '2em';
    const ctx = canvas.getContext("2d");

    ctx.mozImageSmoothingEnabled = false;
    ctx.webkitImageSmoothingEnabled = false;
    ctx.msImageSmoothingEnabled = false;
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(background, 0, 0, background.width * window.devicePixelRatio, background.height * window.devicePixelRatio);

    /* Fetch each image from OpenSea */
    const regex = '\/0x(.*)';
    const imageOneUrl = imageOne.match(regex);

    if (imageOneUrl && imageOneUrl !== null) {
      const apiUrl = `${openSeaApiUrl}${imageOneUrl[0]}`
      const getAsset = await fetch(apiUrl);
      const data = await getAsset.json();
      const imgUrl = data.image_original_url;

      const imageOne = new Image();
      imageOne.setAttribute('crossOrigin', 'anonymous');
      imageOne.src = imgUrl;

      if (bannerStyle === '5_equal_light') {
        imageOne.onload = function() {
          ctx.drawImage(imageOne, 297, 182, 131 * window.devicePixelRatio, 178 * window.devicePixelRatio);
        }
      } else if (bannerStyle === '5_diff_dark') {
        imageOne.onload = function() {
          ctx.drawImage(imageOne, 248, 382.5, 105 * window.devicePixelRatio, 136 * window.devicePixelRatio);
        }
      } else if (bannerStyle === '3_equal_light') {
        imageOne.onload = function() {
          ctx.drawImage(imageOne, 320, 140, 248 * window.devicePixelRatio, 247 * window.devicePixelRatio);
        }
      }

    }

    const imageTwoUrl = imageTwo.match(regex);
    if (imageTwoUrl && imageTwoUrl !== null) {
      const apiUrl = `${openSeaApiUrl}${imageTwoUrl[0]}`
      const getAsset = await fetch(apiUrl);
      const data = await getAsset.json();

      const imgUrl = data.image_original_url;

      const imageTwo = new Image();
      imageTwo.setAttribute('crossOrigin', 'anonymous');
      imageTwo.src = imgUrl;

      if (bannerStyle === '5_equal_light') {
        imageTwo.onload = function(){
          ctx.drawImage(imageTwo, 634, 182, 131 * window.devicePixelRatio, 178 * window.devicePixelRatio);
        }
      } else if (bannerStyle === '5_diff_dark') {
        imageTwo.onload = function() {
          ctx.drawImage(imageTwo, 499, 314.5, 153.5 * window.devicePixelRatio, 203 * window.devicePixelRatio);
        }
      } else if (bannerStyle === '3_equal_light') {
        imageTwo.onload = function() {
          ctx.drawImage(imageTwo, 856, 141, 250 * window.devicePixelRatio, 247 * window.devicePixelRatio);
        }
      }
    }

    const imageThreeUrl = imageThree.match(regex);
    if (imageThreeUrl && imageThreeUrl !== null) {
      const apiUrl = `${openSeaApiUrl}${imageThreeUrl[0]}`
      const getAsset = await fetch(apiUrl);
      const data = await getAsset.json();

      const imgUrl = data.image_original_url;

      const imageThree = new Image();
      imageThree.setAttribute('crossOrigin', 'anonymous');
      imageThree.src = imgUrl;

      if (bannerStyle === '5_equal_light') {
        imageThree.onload = function(){
          ctx.drawImage(imageThree, 971, 182, 131 * window.devicePixelRatio, 178 * window.devicePixelRatio);
        }
      } else if (bannerStyle === '5_diff_dark') {
        imageThree.onload = function() {
          ctx.drawImage(imageThree, 856, 186, 248 * window.devicePixelRatio, 332 * window.devicePixelRatio);
        }
      } else if (bannerStyle === '3_equal_light') {
        imageThree.onload = function() {
          ctx.drawImage(imageThree, 1392, 137, 250 * window.devicePixelRatio, 248 * window.devicePixelRatio);
        }
      }

    }

    const imageFourUrl = imageFour.match(regex);
    if (imageFourUrl && imageFourUrl !== null) {
      const apiUrl = `${openSeaApiUrl}${imageFourUrl[0]}`
      const getAsset = await fetch(apiUrl);
      const data = await getAsset.json();

      const imgUrl = data.image_original_url;

      const imageFour = new Image();
      imageFour.setAttribute('crossOrigin', 'anonymous');
      imageFour.src = imgUrl;

      if (bannerStyle === '5_equal_light') {
        imageFour.onload = function(){
          ctx.drawImage(imageFour, 1306, 182, 131 * window.devicePixelRatio, 178 * window.devicePixelRatio);
        }
      } else if (bannerStyle === '5_diff_dark') {
        imageFour.onload = function() {
          ctx.drawImage(imageFour, 1397, 314.5, 153.5 * window.devicePixelRatio, 203 * window.devicePixelRatio);
        }
      }

    }

    const imageFiveUrl = imageFive.match(regex);
    if (imageFiveUrl && imageFiveUrl !== null) {
      const apiUrl = `${openSeaApiUrl}${imageFiveUrl[0]}`
      const getAsset = await fetch(apiUrl);
      const data = await getAsset.json();

      const imgUrl = data.image_original_url;

      const imageFive = new Image();
      imageFive.setAttribute('crossOrigin', 'anonymous');
      imageFive.src = imgUrl;

      if (bannerStyle === '5_equal_light') {
        imageFive.onload = function(){
          ctx.drawImage(imageFive, 1640, 182, 131 * window.devicePixelRatio, 178 * window.devicePixelRatio);
        }
      } else if (bannerStyle === '5_diff_dark') {
        imageFive.onload = function() {
          ctx.drawImage(imageFive, 1750, 382, 105 * window.devicePixelRatio, 136 * window.devicePixelRatio);
        }
      }

    }

    setInterval(() => {
      console.log('canvas: ', canvas)
      if (canvas.width === 0 && canvas.height === 0) {
        viewPreview();
      } else {
        setGotPreview(true);
        return;
      }
    }, 2000)
  }

  const mint = async () => {
    if (preview === false) {
      viewPreview();
    }

    const canvasToUrl = document.getElementById('canvas').toDataURL("img/png");
    const fetchUrl = await fetch(canvasToUrl);
    const urlToBlob = await fetchUrl.blob();

    /* Get image and upload to IPFS */
    const axios = require('axios');
    const FormData = require('form-data');
    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;

    const data = new FormData();

    // Replace file name with actual token ID
    data.append('file', urlToBlob, {filepath: 'banner_images/token_id.png'});

    /* Upload corresponding metadata file to IPFS */
    axios.post(
      url,
      data,
      {
          headers: {
              'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
              'pinata_api_key': '7c791411dc4715e8c78f', /* Hardcoding this for now */
              'pinata_secret_api_key': 'aaa7b386453057821dc7361a24ab5217af1b7a29ad35a2e8499b41b1300bcfe3' /* Hardcoding this for now */
          }
      }).then(function (response) {
          console.log('response: ', response)
      }).catch(function (error) {
          console.log('error: ', error)
      });


      const metadataUrl = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;

      const metadata = {
        "description": "Custom Twitter banner to showcase NFTs",
        "external_url": "https://openseacreatures.io/3",
        "image": "https://storage.googleapis.com/opensea-prod.appspot.com/puffs/3.png",
        "name": "Custom NFT Twitter Banner",
      }

      /* Upload corresponding metadata file to IPFS */
      axios.post(
        metadataUrl,
        metadata,
        {
            headers: {
                'pinata_api_key': '7c791411dc4715e8c78f', /* Hardcoding this for now */
                'pinata_secret_api_key': 'aaa7b386453057821dc7361a24ab5217af1b7a29ad35a2e8499b41b1300bcfe3' /* Hardcoding this for now */
            }
        }).then(function (response) {
            console.log('response: ', response)
        }).catch(function (error) {
            console.log('error: ', error)
        });


    /* Transfer NFT to user */
  }

  const getOpenSeaUrl = (event) => {
    const name = event.target.name;

    if (name === 'imageOne') {
      setImageOne(event.target.value);
    } else if (name === 'imageTwo') {
      setImageTwo(event.target.value);
    } else if (name === 'imageThree') {
      setImageThree(event.target.value);
    } else if (name === 'imageFour') {
      setImageFour(event.target.value);
    } else {
      setImageFive(event.target.value);
    }
  }

  const slideImages = [
  {
    url: 'images/twitter_1.png',
  },
  {
    url: 'images/synthers_twitter.png',
  },
  {
    url: 'images/twitter_2.png',
  },
  {
    url: 'images/twitter_3.png',
  },
  {
    url: 'images/twitter_4.png',
  },
];

const bannerImages = [
  {
    url: 'images/5_images_1.png',
  },
  {
    url: 'images/5_images_2.png',
  },
  {
    url: 'images/5_images_3.png',
  },
  {
    url: 'images/5_images_5.png',
  },
  {
    url: 'images/3_images_1.png',
  },
  {
    url: 'images/3_images_2.png',
  }
]

  return (
    <div className="App">
      <AppBar>
        <ToolBar>
          <Button>Discord</Button>
          <Button>OpenSea</Button>
          {address === '' ? <Button onClick={connectToWallet}>Connect</Button> : <p className='address'>{address}</p> }
        </ToolBar>
      </AppBar>

      <div className='main-container'>
        <img src='images/dark_blue_background.png' style={{width: '100%'}}></img>

        <div className='main-info-container'>
          <img src='images/header_images.png' style={{width: '100%'}}></img>
          <Button className='open-dialog-btn' onClick={handleGalleryClickOpen}>View Gallery</Button>
          <Button className='open-dialog-btn' onClick={handleClickOpen}>Create Banner</Button>
          <span className='address-err'>{addressErr}</span>
          <img className='learn_more_img' src='images/learn_more.png'></img>
        </div>

      </div>

      <div className='banners-info'>
        <img style={{width: '100%'}} src='images/instructions.png'></img>
      </div>

      <div className='future-banners-container'>
        <img src='images/dark_blue_background.png'></img>
        <img className='future-banners-text' src='images/future_banners_text.png'></img>
        <div className='future-banners-slides'>
          <Slide>
            {bannerImages.map((bannerImage, index)=> (
              <div className="each-slide" key={index}>
              <img className='future-banner-imgs' src={bannerImage.url}></img>
            </div>
            ))}
          </Slide>
        </div>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload the OpenSea URL for your NFTs</DialogTitle>
        <DialogContent>
          <p>Select a Style</p>
          <div class="button">
            <input type="radio" name="banner_style" value="5_equal_light" id="5_equal_light" onChange={(event) => setBannerStyle(event.target.value)}/>
            <img src='images/base_img.png' style={{width: '150px', height: '75px'}}></img>
          </div>
          <div class="button">
            <input type="radio" name="banner_style" value="5_diff_dark" id="5_diff_dark" onChange={(event) => setBannerStyle(event.target.value)}/>
            <img src='images/base_image_5_2.png' style={{width: '150px', height: '75px'}}></img>
          </div>
          {/* <div class="button">
            <input type="radio" name="banner_style" value="5_diff_light" id="5_diff_light" onChange={(event) => setBannerStyle(event.target.value)}/>
            <img src='images/base_image_5_3.png' style={{width: '150px', height: '75px'}}></img>
          </div> */}
          <div class="button">
            <input type="radio" name="banner_style" value="3_equal_light" id="3_equal_light" onChange={(event) => setBannerStyle(event.target.value)}/>
            <img src='images/base_image_3.png' style={{width: '150px', height: '75px'}}></img>
          </div>
          <p>Enter your OpenSea URLs</p>
          {bannerStyle === '3_equal_light' ?
            <div>
              <TextField
                autoFocus
                margin="dense"
                name="imageOne"
                id="imageOne"
                label="OpenSea URL #1"
                type="url"
                fullWidth
                variant="standard"
                onChange={getOpenSeaUrl}
              />
              <TextField
                autoFocus
                margin="dense"
                name="imageTwo"
                id="imageTwo"
                label="OpenSea URL #2"
                type="url"
                fullWidth
                variant="standard"
                onChange={getOpenSeaUrl}
              />
              <TextField
                autoFocus
                margin="dense"
                name="imageThree"
                id="imageThree"
                label="OpenSea URL #3"
                type="url"
                fullWidth
                variant="standard"
                onChange={getOpenSeaUrl}
              />
            </div> :
              <div>
                 <TextField
                autoFocus
                margin="dense"
                name="imageOne"
                id="imageOne"
                label="OpenSea URL #1"
                type="url"
                fullWidth
                variant="standard"
                onChange={getOpenSeaUrl}
              />
              <TextField
                autoFocus
                margin="dense"
                name="imageTwo"
                id="imageTwo"
                label="OpenSea URL #2"
                type="url"
                fullWidth
                variant="standard"
                onChange={getOpenSeaUrl}
              />
              <TextField
                autoFocus
                margin="dense"
                name="imageThree"
                id="imageThree"
                label="OpenSea URL #3"
                type="url"
                fullWidth
                variant="standard"
                onChange={getOpenSeaUrl}
              />
              <TextField
                autoFocus
                margin="dense"
                name="imageFour"
                id="imageFour"
                label="OpenSea URL #4"
                type="url"
                fullWidth
                variant="standard"
                onChange={getOpenSeaUrl}
              />
              <TextField
                autoFocus
                margin="dense"
                name="imageFive"
                id="imageFive"
                label="OpenSea URL #5"
                type="url"
                fullWidth
                variant="standard"
                onChange={getOpenSeaUrl}
              />
            </div>
          }
          {errMsg === '' ? null : <p>{errMsg}</p>}
          {gotPreview === false ? <div className='progress-bar'><CircularProgress></CircularProgress></div> : null}
          <canvas id="canvas"></canvas>
        </DialogContent>
        <DialogActions>
          <Button className='view-preview' onClick={viewPreview} disabled={(bannerStyle === '3_equal_light' ? imageOne === '' || imageTwo === '' || imageThree === '' : imageOne === '' || imageTwo === '' || imageThree === '' || imageFour === '' || imageFive === '') || bannerStyle === ''}>View Preview</Button>
          <Button className='mint' onClick={mint} disabled={address === '' || (bannerStyle === '3_equal_light' ? imageOne === '' || imageTwo === '' || imageThree === '' : imageOne === '' || imageTwo === '' || imageThree === '' || imageFour === '' || imageFive === '') || bannerStyle === ''}>Mint</Button>
          <Button className='cancel' onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={galleryOpen} onClose={handleGalleryClose}>
        <DialogTitle>Banner Gallery</DialogTitle>
        <DialogContent>
          <div>
            <Slide>
              {slideImages.map((slideImage, index)=> (
                <div className="each-slide" key={index}>
                <img className='gallery-imgs' src={slideImage.url}></img>
              </div>
              ))}
            </Slide>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className='cancel' onClick={handleGalleryClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default App;
